import { Typography } from 'antd';
import styled, { css } from 'styled-components';
import { TitlePropsWithChildren } from './Title';

export const StyledTitle = styled(Typography.Title)<TitlePropsWithChildren>`
  && {
    text-align: left;
    margin-bottom: 12px;
    color: ${({ theme }) => theme.antd.colorTextPrimary};
    font-weight: 400;
    font-size: 3em;
    ${(props) =>
      props.$center &&
      css`
        text-align: center;
      `}
  }
`;
