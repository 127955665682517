import { Progress, Tooltip } from 'antd';
import { StatusType, StyledCharCounter } from './styled';

interface IProps {
  value: string | undefined;
  max?: number;
}

export interface IStyledCharCounter {
  length: number;
  max?: number;
  percent: number;
  $status: StatusType;
}

const CharCounter: React.FC<IProps> = ({ value, max, ...props }) => {
  const length = value ? value.length : 0;
  const percent = max ? (length / max) * 100 : 0;

  let status = StatusType.DEFAULT;
  if (max && length <= max && length > max - 10) {
    status = StatusType.WARNING;
  } else if (percent > 100) {
    status = StatusType.EXCEPTION;
  } else if (length > 0) {
    status = StatusType.SUCCESS;
  }

  return (
    <StyledCharCounter length={length} max={max} percent={percent} $status={status}>
      <Tooltip title={`${length}/${max}`}>
        <Progress
          type="circle"
          percent={percent}
          size={22}
          strokeWidth={11}
          showInfo={false}
          trailColor="rgb(223, 223, 223)"
        />
      </Tooltip>
    </StyledCharCounter>
  );
};

export default CharCounter;
