import { UploadProps } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import React, { useContext } from 'react';
import { UploadContext } from '@common/contexts/Upload/UploadContext';
import { useIntl } from 'react-intl';
import { StyledLabel, StylesFileUpload } from './styled';

interface FileUploadProps extends UploadProps {
  visible?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = ({ visible = true }) => {
  const intl = useIntl();
  const { fileList, updateFileList } = useContext(UploadContext);
  if (!visible) {
    return null;
  }

  const props: UploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      updateFileList(newFileList);
    },
    beforeUpload: (file) => {
      updateFileList([...fileList, file]);
      return false;
    },
    fileList
  };

  return (
    <>
      <StyledLabel>{intl.formatMessage({ id: 'form.fileUpload' })}</StyledLabel>
      <StylesFileUpload listType="picture" {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">{intl.formatMessage({ id: 'upload.dropText' })}</p>
      </StylesFileUpload>
    </>
  );
};

export default FileUpload;
