import { FormattedDate, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import { ITicketListModelDTO } from '@common/models/TicketModel';
import PageLoader from '@common/components/PageLoader';
import {
  StyledCircleSolidWallOrange,
  StyledPlusOrange,
  StyledTicketColumnContent,
  StyledTicketColumnLeft,
  StyledTicketWrapper
} from '@common/components/TicketLayout';
import { buildRouteUrl } from '@common/helpers/OtherHelper';
import { NavigatePaths } from '@common/constants/Paths';
import { useEffectOnce } from '@common/helpers/ActionHelper';
import { ColumnsType } from 'antd/es/table';
import { ConfigProvider, Divider, Table, Tooltip } from 'antd';
import Icon from '@common/components/Icon';
import { faArrowUpRightFromSquare, faCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import TicketStatus from './components/TicketStatus';
import Empty from '@common/components/Empty';
import ListHeader from './components/ListHeader';
import Search from './components/ListHeader/components/Search';
import { ListContext } from './context/List/ListContext';
import PageHeader from './components/PageHeader';
import { StyledLink, StyledTicketAction, StyledTicketList, StyledTicketListLogo } from './styled';

const TicketList: React.FC = () => {
  const intl = useIntl();
  const { tickets, fetchTickets } = useContext(ListContext);

  const { instanceSlug } = useParams<{
    instanceSlug: string;
  }>();

  const [isAppReady, setAppReady] = useState(false);

  const getTicketViewUrl = (shortCode: string) => {
    return buildRouteUrl(NavigatePaths.TicketView, [
      {
        key: ':instanceSlug',
        value: instanceSlug
      },
      {
        key: ':shortCode',
        value: shortCode
      },
      {
        key: '/:externalToken',
        value: ''
      }
    ]);
  };

  const getTicketCreate = (shortCode: string) => {
    return buildRouteUrl(NavigatePaths.TicketCreate, [
      {
        key: ':instanceSlug',
        value: instanceSlug
      },
      {
        key: ':shortCode',
        value: shortCode
      }
    ]);
  };

  const columns: ColumnsType<ITicketListModelDTO> = [
    {
      title: intl.formatMessage({ id: 'list.column.number' }),
      dataIndex: 'no',
      width: '90px',
      render: (no, { shortCode }) => <StyledLink to={getTicketViewUrl(shortCode)}>#{no}</StyledLink>
    },
    {
      title: intl.formatMessage({ id: 'list.column.subject' }),
      dataIndex: 'subject',
      width: '20%',
      render: (subject, { shortCode }) => <StyledLink to={getTicketViewUrl(shortCode)}>{subject}</StyledLink>
    },
    {
      title: intl.formatMessage({ id: 'list.column.status' }),
      dataIndex: 'isOpen',
      width: '100px',
      render: (isOpen) => <TicketStatus $isOpen={isOpen} />
    },
    {
      title: intl.formatMessage({ id: 'list.column.formName' }),
      dataIndex: 'ticketForm',
      render: (ticketForm) =>
        ticketForm ? `${ticketForm.name}` : intl.formatMessage({ id: 'list.form.operatorTicket' })
    },
    {
      title: intl.formatMessage({ id: 'list.column.createdAt' }),
      dataIndex: 'createdAt',
      render: (createdAt) => (
        <FormattedDate value={createdAt} year="numeric" month="long" day="2-digit" hour="numeric" minute="numeric" />
      )
    },
    {
      render: ({ shortCode, ticketForm }) => {
        return (
          <StyledTicketAction>
            {ticketForm ? (
              <Tooltip title={intl.formatMessage({ id: 'list.action.addTicket' })}>
                <StyledLink to={getTicketCreate(ticketForm.shortCode)}>
                  <Icon icon={faCirclePlus} />
                </StyledLink>
              </Tooltip>
            ) : null}
            <Tooltip title={intl.formatMessage({ id: 'list.action.viewTicket' })}>
              <StyledLink to={getTicketViewUrl(shortCode)}>
                <Icon icon={faArrowUpRightFromSquare} />
              </StyledLink>
            </Tooltip>
          </StyledTicketAction>
        );
      }
    }
  ];

  useEffectOnce(() => {
    const loadData = async () => {
      try {
        await fetchTickets('');
      } catch (err: any) {
        setAppReady(true);
      } finally {
        setAppReady(true);
      }
    };
    loadData();
  });

  if (!isAppReady) {
    return <PageLoader />;
  }

  return (
    <StyledTicketWrapper>
      <StyledTicketList>
        <StyledTicketColumnLeft>
          <StyledPlusOrange />
          <StyledCircleSolidWallOrange />
        </StyledTicketColumnLeft>
        <StyledTicketColumnContent>
          <PageHeader />
          <Divider />
          <ListHeader>
            <Search />
          </ListHeader>

          <ConfigProvider renderEmpty={() => <Empty emptyMessage={intl.formatMessage({ id: 'list.emptyTickets' })} />}>
            <Table columns={columns} rowKey={(record) => record.id} dataSource={tickets} pagination={false} />
          </ConfigProvider>
        </StyledTicketColumnContent>
      </StyledTicketList>
      <StyledTicketListLogo />
    </StyledTicketWrapper>
  );
};

export default TicketList;
