import { IHttpResponse } from '@common/interfaces/HttpInterface';
import { ITicketModelDTO } from '@common/models/TicketModel';
import { mainApi } from '@common/services/ApiAgentService';
import { AxiosRequestConfig } from 'axios';

export interface ITokenAuthRequestData {
  instanceShortCode: string;
  ticketToken: string;
}

export interface ITokenAuthResponse {
  instanceShortCode: string;
  ticketToken: string;
  ticketShortCode: ITicketModelDTO;
  token: string;
  ticketClientEmail: string;
}

export interface IByCodeRequestData {
  instanceShortCode: string;
  email: string;
}

export interface IByCodeData {
  code: string;
  email: string;
}

export interface IByCodeResponse {
  token: string;
}

export interface IValidateResponse {
  isValid: boolean;
}

export const TokenAuthResource = {
  auth: (data: ITokenAuthRequestData, config: AxiosRequestConfig = {}): Promise<IHttpResponse<ITokenAuthResponse>> =>
    mainApi.post(
      `/token-auth/`,
      { ...data },
      {
        ...config,
        headers: {
          Accept: 'application/json',
          ...config.headers
        }
      }
    ),
  byCodeRequest: (
    data: IByCodeRequestData,
    rc_token: string,
    config: AxiosRequestConfig = {}
  ): Promise<IHttpResponse<any>> => mainApi.post(`/token-auth/by-code/request/`, { ...data, rc_token }),
  byCode: (
    data: IByCodeData,
    rc_token: string,
    config: AxiosRequestConfig = {}
  ): Promise<IHttpResponse<IByCodeResponse>> => mainApi.post(`/token-auth/by-code/`, { ...data, rc_token }),
  validate: (
    email: string,
    token: string,
    config: AxiosRequestConfig = {}
  ): Promise<IHttpResponse<IValidateResponse>> =>
    mainApi.post(
      `token-auth/check-email-token-pair/`,
      { email, token },
      {
        ...config,
        headers: {
          Accept: 'application/json',
          ...config.headers
        }
      }
    )
};
