import { createIntl, createIntlCache } from 'react-intl';
import { AppLocale } from '@common/lang';
import { getIntlLanguageByBrowser } from './LanguageHelper';

const cache = createIntlCache();

export const formatBytes = (bytes: number, decimals: number): string => {
  const language = getIntlLanguageByBrowser(navigator?.language);
  const intl = createIntl(AppLocale[language], cache);

  if (bytes) {
    if (bytes === 0) return `0 ${intl.formatMessage({ id: 'bytes' })}`;

    const k: number = 1000;
    const dm: number = decimals < 0 ? 0 : decimals;
    const sizes = [intl.formatMessage({ id: 'bytes' }), 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  } else {
    return '';
  }
};
