import {
  ITicketFormModelField,
  ITicketFormModelShortDTO,
  TicketFormModelCreateSubmittedFieldAnswer,
  TicketFormModelCreateSubmittedFieldChoice
} from './TicketFormModel';

export enum TicketModelSourceType {
  FORM = 'form',
  MANUAL = 'manual',
  EMAIL = 'email'
}

export interface ITicketModelSubmittedFormItem extends ITicketFormModelField {
  answer?: TicketFormModelCreateSubmittedFieldAnswer;
  choice?: TicketFormModelCreateSubmittedFieldChoice;
}

export interface ITicketListModelDTO {
  id: string;
  shortCode: string;
  no: number;
  subject: string;
  isOpen: boolean;
  statusChangedAt: string;
  createdAt: string;
  ticketForm: ITicketFormModelShortDTO | null;
}

export interface ITicketModelDTO {
  id: string;
  sourceType: TicketModelSourceType;
  isInternal: boolean;
  allowForAttachments: boolean;
  shortCode: string;
  no: number;
  subject: string;
  clientName: string;
  clientEmail: string;
  isOpen: boolean;
  statusChangedAt: string;
  createdAt: string;
  ticketForm: ITicketFormModelShortDTO | null;
  submittedForm: ITicketModelSubmittedFormItem[];
}

class TicketModel implements ITicketModelDTO {
  constructor(
    public id: string,
    public sourceType: TicketModelSourceType,
    public isInternal: boolean,
    public allowForAttachments: boolean,
    public shortCode: string,
    public no: number,
    public subject: string,
    public clientName: string,
    public clientEmail: string,
    public isOpen: boolean,
    public statusChangedAt: string,
    public createdAt: string,
    public ticketForm: ITicketFormModelShortDTO | null,
    public submittedForm: ITicketFormModelField[]
  ) {}

  static build = (dto: ITicketModelDTO) =>
    new TicketModel(
      dto.id,
      dto.sourceType,
      dto.isInternal,
      dto.allowForAttachments,
      dto.shortCode,
      dto.no,
      dto.subject,
      dto.clientName,
      dto.clientEmail,
      dto.isOpen,
      dto.statusChangedAt,
      dto.createdAt,
      dto.ticketForm,
      dto.submittedForm
    );

  static serialize() {}
}

export default TicketModel;
