import { Input, Row } from 'antd';
import React, { useEffect } from 'react';
import { useRef } from 'react';
import { InputRef } from 'antd/es/input';
import { StyledPinInput } from './styled';

interface FormPinInputProps {
  value: string;
  numInputs: number;
  onChange: (code: string) => void;
  onComplete: () => void;
  focus?: boolean;
  isDisabled?: boolean;
}

const FormPinInput = ({ value, numInputs, onChange, onComplete, focus = true, isDisabled }: FormPinInputProps) => {
  const [activeInput, setActiveInput] = React.useState(0);
  const inputRefs = useRef<InputRef[]>([]);

  const getCodeValue = () => value.toString().split('');

  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, numInputs);
  }, [numInputs]);

  useEffect(() => {
    if (focus) {
      inputRefs.current[0]?.focus();
    }
  }, [focus]);

  useEffect(() => {
    if (numInputs === value.length) {
      onComplete();
    }
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value) {
      changeCodeAtFocus(value);
    } else {
      event.preventDefault();

      changeCodeAtFocus('');
      focusInput(activeInput - 1);
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>, index: number) => {
    setActiveInput(index);
    event.target.select();
  };

  const handleBlur = () => {
    setActiveInput(activeInput - 1);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const code = getCodeValue();

    if (event.code === 'Backspace') {
      event.preventDefault();

      changeCodeAtFocus('');
      focusInput(activeInput - 1);
    } else if (event.key === code[activeInput]) {
      event.preventDefault();
    }
  };

  const focusInput = (index: number) => {
    const activeInput = Math.max(Math.min(numInputs - 1, index), 0);

    if (inputRefs.current[activeInput]) {
      inputRefs.current[activeInput].focus();
      inputRefs.current[activeInput].select();

      setActiveInput(activeInput);
    }
  };

  const changeCodeAtFocus = (value: string) => {
    const code = getCodeValue();

    code[activeInput] = value[0];
    handleCodeChange(code);
  };

  const handleCodeChange = (code: Array<string>) => {
    const codeValue = code.join('');
    const reg = /^\d+$/;

    if (reg.test(codeValue) || codeValue === '') {
      focusInput(activeInput + 1);
      onChange(codeValue);
    } else {
      return;
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const code = getCodeValue();
    let nextActiveInput = activeInput;
    const reg = /^\d+$/;

    const pastedData = event.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');

    for (let i = 0; i < numInputs; ++i) {
      if (i >= activeInput && pastedData.length > 0) {
        if (reg.test(pastedData[i]) || (pastedData[i] === '' && pastedData.length === numInputs)) {
          code[i] = pastedData[i] ?? '';
          nextActiveInput++;
        } else {
          return;
        }
      }
    }

    focusInput(nextActiveInput);
    handleCodeChange(code);
  };

  return (
    <Row>
      {Array.from({ length: numInputs }, (_, index) => index).map((index) => (
        <StyledPinInput key={index}>
          <Input
            value={getCodeValue()[index] ?? ''}
            ref={(ref) => {
              if (ref) {
                inputRefs.current[index] = ref;
              }
            }}
            onChange={handleChange}
            onFocus={(event) => handleFocus(event, index)}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            maxLength={1}
            disabled={isDisabled}
          />
        </StyledPinInput>
      ))}
    </Row>
  );
};

export default FormPinInput;
