import { StyledIcon } from '@common/components/Icon/styled';
import {
  StyledCircleOrange,
  StyledPlusPrimary,
  StyledTicketColumnContent,
  StyledTicketColumnRight,
  StyledTicketWrapper
} from '@common/components/TicketLayout';
import { StyledTitle } from '@common/components/Title/styled';
import styled from 'styled-components';
import { StyledTicketCreate } from '../TicketCreate/styled';
import { devices } from '@common/constants/LayoutParams';
import { StyledTextAreaWrapper } from '@common/components/FormTextArea/styled';
import ActionButton from '@common/components/ActionButton/ActionButton';

export const StyledTicketHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 40px 0;

  ${StyledIcon} {
    font-size: 2.2em;
    width: 50px;
    text-align: right;
    cursor: pointer;

    padding: 10px 0;
    margin: 0;
  }

  ${StyledTitle} {
    margin: 0 0 0 66px;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
  }
`;

export const StyledTicketInfoWrapper = styled(StyledTicketWrapper)``;

export const StyledGoToListButton = styled(ActionButton)``;

export const StyledTicketView = styled(StyledTicketCreate)`
  align-items: start;
  height: 100%vh;

  ${StyledTicketColumnContent} {
    ${StyledGoToListButton} {
      padding: 5px 20px;
      height: auto;
    }
  }

  @media ${devices.Small} {
    align-items: center;
    flex-direction: column;

    ${StyledTicketColumnContent} {
      width: 90%;
    }

    ${StyledPlusPrimary} {
      display: none;
    }

    ${StyledCircleOrange} {
      display: none;
    }

    ${StyledTicketColumnRight} {
      width: 90%;
    }
  }

  ${StyledTicketColumnRight} {
    padding: 0 14px 30px 94px;
    min-height: 500px;

    ${StyledPlusPrimary} {
      top: 60px;
    }

    @media ${devices.Small} {
      display: block;
      padding: 0;
    }
  }

  ${StyledTextAreaWrapper} {
    Textarea {
      height: 120px;
    }
  }

  ${StyledTicketColumnContent} {
    margin-top: 88px;
  }
`;
