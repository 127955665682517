import { IHttpResponse } from '@common/interfaces/HttpInterface';
import { ITicketFormModelCreateDTO, ITicketFormModelDTO } from '@common/models/TicketFormModel';
import { ITicketMessageModelDTO } from '@common/models/TicketMessage';
import { ITicketModelDTO } from '@common/models/TicketModel';
import { mainApi } from '@common/services/ApiAgentService';
import { AxiosRequestConfig } from 'axios';

export interface ITicketCreateResponse {
  externalTicketToken: string;
  ticket: ITicketModelDTO;
  ticketMessages: ITicketMessageModelDTO[];
}

export const TicketCreateResource = {
  get: (
    instanceSlug: string,
    shortCode: string,
    config: AxiosRequestConfig = {}
  ): Promise<IHttpResponse<ITicketFormModelDTO>> =>
    mainApi.get(`/ticket-form/${instanceSlug}/${shortCode}/`, {
      ...config,
      headers: {
        Accept: 'application/json',
        ...config.headers
      }
    }),
  create: (
    instanceSlug: string,
    shortCode: string,
    data: ITicketFormModelCreateDTO,
    rc_token: string,
    config: AxiosRequestConfig = {}
  ): Promise<IHttpResponse<ITicketCreateResponse>> => {
    return mainApi.post(`/ticket-form/${instanceSlug}/${shortCode}/`, { ...data, rc_token });
  }
};
