import { createContext } from 'react';

export interface ICaptchaContext {
  renderInfo: () => JSX.Element | null;
  isReady: boolean;
  executeCaptcha: (onSubmit: (token: string) => Promise<void>) => Promise<void>;
}

export const CaptchaContext = createContext<ICaptchaContext>({
  isReady: false,
  renderInfo: () => null,
  executeCaptcha: async () => {}
});
