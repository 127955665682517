import { IntlLang } from '@common/enums/IntlLang';
import { ITranslationConfig } from '@common/lang/config/types';
import { en, pl, de } from './config';

export type AppLocaleType = {
  [key in IntlLang]: ITranslationConfig;
};

const AppLocale: AppLocaleType = {
  pl,
  en,
  de
};

export { AppLocale };
