import { IntlLang } from '@common/enums/IntlLang';
import pl_PL from 'antd/locale/pl_PL';
import en_US from 'antd/locale/en_US';
import de_DE from 'antd/locale/de_DE';

const getIntlLanguageByBrowser = (lang: string | undefined = ''): IntlLang => {
  switch (lang.toLowerCase()) {
    case 'de':
    case 'de-de':
      return IntlLang.de;

    case 'pl':
    case 'pl-pl':
      return IntlLang.pl;

    default:
      return IntlLang.en;
  }
};

const getAntdLocaleByBrowser = (lang: string | undefined = '') => {
  switch (lang.toLowerCase()) {
    case 'de':
    case 'de-de':
      return de_DE;

    case 'pl':
    case 'pl-pl':
      return pl_PL;

    default: {
      return en_US;
    }
  }
};

export { getIntlLanguageByBrowser, getAntdLocaleByBrowser };
