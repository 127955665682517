import { StyledTitle } from '@common/components/Title/styled';
import { Button } from 'antd';
import styled from 'styled-components';

export const StyledPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${StyledTitle} {
    margin-top: 0;
  }
`;

export const StyledLogoutButton = styled(Button)`
  display: inline-block;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.sunsetOrange[500]};
  border-radius: 16px;
  font-size: 1.1em;
  color: ${({ theme }) => theme.antd.colorTextLightSolid};
  font-weight: bold;

  &&& {
    &:hover {
      background-color: ${({ theme }) => theme.colors.sunsetOrange[600]};
      border-color: ${({ theme }) => theme.colors.sunsetOrange[600]};
      color: ${({ theme }) => theme.antd.colorTextLightSolid};
      box-shadow: none;
    }
  }
`;
