import styled from 'styled-components';

export const StyledPinInput = styled.div`
  input {
    max-width: 85px;
    text-align: center !important;
    height: 80px;
    margin: 20px 5px !important;
    font-size: 22px !important;
    font-weight: 700 !important;
    outline: none !important;
    box-shadow: none;
  }
`;
