import { StyledForm } from '@common/components/Form/styled';
import { StyledText } from '@common/components/Text/styled';
import { StyledTicketColumnContent } from '@common/components/TicketLayout';
import styled from 'styled-components';

export const StyledTicketCreate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin: 80px 60px 0;
  flex: 1 1;
  & > .TicketCreateColumn {
    display: flex;
    flex-direction: column;
  }
  ${StyledTicketColumnContent} {
    padding-top: 30px;
  }
  ${StyledText} {
    font-size: 1.1em;
  }

  ${StyledForm} {
    margin-top: 40px;
  }
`;
