import { getAntdLocaleByBrowser, getIntlLanguageByBrowser } from '@common/helpers/LanguageHelper';
import { AppLocale } from '@common/lang';
import { ConfigProvider, theme } from 'antd';
import { PropsWithChildren, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import { Colors } from '@ngroup-pl/icp-theme';
import { ConfigProviderProps } from 'antd/es/config-provider';
import CaptchaProvider from '@common/contexts/Captcha/CaptchaProvider';

export const AppConfig: React.FC<PropsWithChildren> = ({ children }) => {
  const language = getIntlLanguageByBrowser(navigator?.language);
  const antdLocale = getAntdLocaleByBrowser(navigator?.language);

  const appLocale = useMemo(() => {
    return {
      intl: (AppLocale as any)[language],
      messages: AppLocale[language].messages
    };
  }, [language]);

  const config: ConfigProviderProps = {
    componentSize: 'large',
    locale: antdLocale,
    theme: {
      token: {
        colorBorder: Colors.coolGray[50],
        colorBorderSecondary: Colors.coolGray[50]
      }
    }
  };

  const { token } = theme.useToken();

  return (
    <ConfigProvider {...config}>
      <IntlProvider locale={language} messages={appLocale.messages}>
        <ThemeProvider
          theme={{
            antd: token,
            colors: Colors
          }}
        >
          <CaptchaProvider>{children}</CaptchaProvider>
        </ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
};
