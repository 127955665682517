import React, { PropsWithChildren } from 'react';
import { FormProps } from 'antd';
import { ITicketFormModelField } from '@common/models/TicketFormModel';
import TicketFormField from '../TicketFormField';
import { Rule } from 'antd/es/form';
import { useIntl } from 'react-intl';
import Form from '@common/components/Form';

interface ITicketForm extends FormProps {
  fields?: ITicketFormModelField[];
}
type ITicketFormComponent = ITicketForm & PropsWithChildren;

const TicketForm: React.FC<ITicketFormComponent> = ({ children, fields, ...props }) => {
  const intl = useIntl();

  const getRules = (field: ITicketFormModelField) => {
    const result: Rule[] = [];

    if (field.isRequired) {
      result.push({ required: field.isRequired, message: intl.formatMessage({ id: 'form.required.message' }) });
    }

    if (field.maxLength) {
      result.push({
        max: field.maxLength,
        message: intl.formatMessage({ id: 'form.maxLength.message' })
      });
    }

    if (field.isEmail) {
      result.push({
        type: 'email',
        message: intl.formatMessage({ id: 'form.required.email' })
      });
    }

    return result;
  };

  return (
    <Form fields={fields} {...props}>
      {fields?.map((field: ITicketFormModelField) => (
        <TicketFormField
          field={field}
          getRules={getRules}
          key={field.order}
          label={field.name}
          id={'formField' + field.order}
          name={'formField' + field.order}
        />
      ))}
      {children}
    </Form>
  );
};

export default TicketForm;
