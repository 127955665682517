import { Form } from 'antd';
import styled from 'styled-components';

export const StyledForm = styled(Form)`
  && .ant-form-item-label {
    label {
      height: 20px;
      min-height: 20px;
      color: ${({ theme: { antd } }) => antd.colorText};
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
      display: flex;
      &.ant-form-item-required {
        &::before {
          display: none;
          content: '';
        }
        &::after {
          display: inline-block;
          margin-right: 4px;
          color: ${({ theme: { antd } }) => antd.colorTextQuaternary};
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: '*';
        }
      }
    }
  }
  &&.ant-form-item-has-error {
    label {
      &.ant-form-item-required {
        color: ${({ theme: { antd } }) => antd.colorTextError};
        &::after {
          color: ${({ theme: { antd } }) => antd.colorTextError};
        }
      }
    }
  }
`;
