import { StyledIcon } from '@common/components/Icon/styled';
import { Avatar } from 'antd';
import { darken, lighten } from 'polished';
import styled from 'styled-components';

export const StyledMessageContent = styled.div`
  padding: 10px 20px 20px;
  border-radius: 10px;
  flex: 1;
  margin-left: 20px;
`;

export const StyledMessageFiles = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
`;

export const StyledAvatar = styled(Avatar)`
  min-width: 48px;
  color: ${({ theme: { antd } }) => antd.colorText};
`;

export const StyledFileInfoSize = styled.div`
  font-size: 0.9em;
  color: ${({ theme: { antd } }) => antd.colorText};
`;

export const StyledTicketMessage = styled.div<{ $external: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 20px;
  ${StyledMessageContent} {
    background-color: ${({ $external, theme: { antd } }) => ($external ? antd.colorFillQuaternary : antd.colorPrimary)};
    color: ${({ $external, theme: { antd } }) => ($external ? antd.colorText : antd.colorTextLightSolid)};
    a {
      color: ${({ $external, theme: { antd } }) => ($external ? antd.colorText : antd.colorTextLightSolid)};
    }
  }

  ${StyledMessageFiles} {
    border-top: 1px solid
      ${({ $external, theme: { antd } }) =>
        $external ? lighten(0.16, antd.colorFillQuaternary) : darken(0.06, antd.colorPrimary)};
  }

  ${StyledAvatar} {
    background-color: ${({ $external, theme: { antd } }) => antd.colorFillQuaternary};
    color: ${({ theme: { antd } }) => antd.colorTextSecondary};
  }

  ${StyledFileInfoSize} {
    color: ${({ $external, theme: { antd } }) => ($external ? antd.colorText : antd.colorTextLightSolid)};
  }
`;

export const StyledMessageFileItem = styled.div`
  display: flex;
  padding: 5px 0;
  flex-direction: row;
  cursor: pointer;

  ${StyledIcon} {
    font-size: 2.4em;
  }
`;

export const StyledFileInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledFileInfoFileName = styled.div`
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const StyledFormattedDate = styled.span`
  margin-left: 2px;
`;
