import { StyledForm } from '@common/components/Form/styled';
import { Spin } from 'antd';
import styled from 'styled-components';

export const StyledFormWrapper = styled.div`
  display: flex;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 180px;

  ${StyledForm} {
    flex-basis: 100%;
    width: 100%;
  }
`;

export const StyledSpin = styled(Spin)`
  .ant-spin-dot-item {
    background-color: ${({ theme: { antd } }) => antd.colorPrimary};
  }
`;
