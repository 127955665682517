import React, { useEffect, useRef, useState } from 'react';
import { CaptchaContext } from '@common/contexts/Captcha/CaptchaContext';
import styled from 'styled-components';

const StyledCaptcha = styled.div`
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

const CaptchaProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const scriptRef = useRef<HTMLScriptElement>();
  const widgetRef = useRef<HTMLDivElement>();

  useEffect(() => {
    loadScript();

    return () => {
      widgetRef.current && document.body.removeChild(widgetRef.current);
      scriptRef.current && document.body.removeChild(scriptRef.current);
    };
  }, []);

  const onLoad = (): void => {
    const isScriptExist = document.getElementById('g-recaptcha');

    if (!isScriptExist) {
      const widget = document.createElement('div');
      widget.id = 'g-recaptcha';
      widgetRef.current = document.body.appendChild(widget);

      window.grecaptcha.render('g-recaptcha', {
        sitekey: `${process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}`,
        size: 'invisible'
      });

      window.grecaptcha.ready(() => {
        setIsReady(true);
      });
    }
  };

  const loadScript = () => {
    const isScriptExist = document.getElementById('g-recaptcha-script');
    window.captchaOnLoad = onLoad;

    if (!isScriptExist) {
      const url = `https://www.google.com/recaptcha/api.js`;
      const queryString = `?onload=captchaOnLoad&render=explicit`;

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `${url}${queryString}`;
      script.id = 'g-recaptcha-script';
      script.async = true;
      script.defer = true;

      scriptRef.current = document.body.appendChild(script);
    }
  };

  const executeCaptcha = (onSubmit: (token: string) => Promise<void>) => {
    return window.grecaptcha.execute({ action: 'submit' }).then((token: string) => {
      onSubmit(token);
    });
  };

  const renderInfo = () =>
    isReady ? (
      <span>
        This site is protected by reCAPTCHA and the Google{' '}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{' '}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </span>
    ) : null;

  return (
    <StyledCaptcha>
      <CaptchaContext.Provider value={{ renderInfo, executeCaptcha, isReady }}>{children}</CaptchaContext.Provider>
    </StyledCaptcha>
  );
};

export default CaptchaProvider;
