import styled from 'styled-components';

export const StyledRoundedBox = styled.div`
  display: inline-block;
  padding: 10px 20px;
  background-color: ${({ theme }) => theme.colors.sunsetOrange[500]};
  border-radius: 16px;
  font-size: 1.1em;
  color: ${({ theme }) => theme.antd.colorTextLightSolid};
  font-weight: bold;
`;
