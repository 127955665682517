import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { FileExtension, FileExtensionType, FileExtensionTypeIcon } from '@common/constants/FileExtension';
import { formatBytes } from '@common/helpers/MemoryUnitsHelper';

export const getFileIconByExtension = (extension: string | undefined): IconProp => {
  if (extension) {
    let fileType: FileExtensionType | undefined;

    Object.entries(FileExtension).forEach(([fileTypeKey, fileTypeValue]) => {
      fileTypeValue.forEach((value) => {
        if (value === extension) {
          fileType = fileTypeKey as FileExtensionType;
        }
      });
    });

    if (fileType) {
      return FileExtensionTypeIcon[fileType];
    } else {
      return FileExtensionTypeIcon.otherFile;
    }
  }

  return FileExtensionTypeIcon.otherFile;
};

export const getFileExtension = (fileName: string): string | undefined => {
  const index = fileName.lastIndexOf('.');
  return index > 0 ? fileName.substring(index + 1) : undefined;
};

export const formatFileSize = (size: number | undefined) => {
  if (!size) {
    return '';
  }

  return formatBytes(size, 2);
};

export const downloadFileFromUrl = (url: string, filename: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);

  document.body.appendChild(link);

  link.click();

  link.parentNode?.removeChild(link);
};
