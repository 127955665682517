import styled from 'styled-components';

export const StyledLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 50px 0 0;
  font-size: 0.75rem;
`;

export const StyledLogo = styled.img.attrs(({ src }) => ({
  src: src,
  alt: 'Logo'
}))`
  height: 20px;
`;
