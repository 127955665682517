import * as _ from 'lodash';

const getFullUrl = (): string => {
  return window.location.href;
};

const buildRouteUrl = (url: string, params: { key: string; value: any }[]): string => {
  let parsedUrl = url;

  _.forEach(params, ({ key, value }) => {
    parsedUrl = parsedUrl.replace(key, value);
  });

  return parsedUrl;
};

const getParamFromString = (text: string, key: string, separator: string = '/'): string | undefined => {
  if (text) {
    const split = text.split(separator);
    const findIndex = split.indexOf(key);

    if (findIndex > -1) {
      return split[findIndex + 1];
    }
  }
};

const removeHtmlFromString = (text: string) => {
  return text.replace(/(<([^>]+)>)/gi, '');
};


export { getFullUrl, buildRouteUrl, getParamFromString, removeHtmlFromString };
