import * as React from 'react';
import { Ref } from 'react';
import AntIcon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/es/components/Icon';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { StyledIcon } from './styled';

export interface IIconProps extends IconComponentProps {
  icon: IconProp;
  fontAwesomeIconProps?: Omit<FontAwesomeIconProps, 'icon'>;
}

const Icon = React.forwardRef(
  ({ icon, fontAwesomeIconProps, className, ref, ...props }: IIconProps, _ref: Ref<typeof AntIcon>): JSX.Element => (
    <StyledIcon>
      <AntIcon component={() => <FontAwesomeIcon icon={icon} {...fontAwesomeIconProps} />} {...props} />
    </StyledIcon>
  )
);

export default Icon;
