import { Upload } from 'antd';
import styled from 'styled-components';
const { Dragger } = Upload;

export const StylesFileUpload = styled(Dragger)`` as typeof Dragger;

export const StyledLabel = styled.label`
  && {
    height: 20px;
    color: ${({ theme: { antd } }) => antd.colorTextLabel};
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    margin-bottom: 10px;
  }
`;
