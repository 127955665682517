import styled from 'styled-components';
import { IProps } from './TicketStatus';

export const StyledTicketStatus = styled.div<IProps>`
  background-color: ${({ $isOpen, theme: { antd } }) => ($isOpen ? antd.colorSuccess : antd.colorError)};
  width: 100%;

  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 5px;
  span {
    font-size: 12px;
    color: ${({ theme: { antd } }) => antd.colorWhite};
  }
`;
