import React, { PropsWithChildren } from 'react';
import { ButtonProps } from 'antd';
import { StyledActionButton } from './styled';

export type StyledActionButtonProps = { $gray?: boolean } & ButtonProps & PropsWithChildren;
type ActionButtonProps = { gray?: boolean; text?: string } & ButtonProps & PropsWithChildren;

const ActionButton: React.FC<ActionButtonProps> = ({ children, gray, text, ...props }) => {
  const renderText = () => text || children;

  return (
    <StyledActionButton type="primary" $gray={gray} size="large" {...props}>
      {renderText()}
    </StyledActionButton>
  );
};

export default ActionButton;
