import { IHttpResponse } from '@common/interfaces/HttpInterface';
import { ITicketModelDTO } from '@common/models/TicketModel';
import { mainApi } from '@common/services/ApiAgentService';
import { AxiosRequestConfig } from 'axios';

export interface ITicketListResponse {
  tickets: ITicketModelDTO[];
}

export const TicketListResource = {
  fetch: (
    instanceSlug: string,
    token: string,
    params: any = {},
    config: AxiosRequestConfig = {}
  ): Promise<IHttpResponse<ITicketListResponse>> =>
    mainApi.get(`/ticket/${instanceSlug}/`, {
      params,
      ...config,
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${token}`,
        ...config.headers
      }
    })
};
