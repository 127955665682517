import { Layout } from 'antd';
import { StyledMain } from './styled';
import MainRoutes from './MainRoutes';
import useApiConfig from '@common/hooks/useApiConfig';

const Main: React.FC = () => {

  useApiConfig();

  return (
    <Layout>
      <StyledMain>
        <MainRoutes />
      </StyledMain>
    </Layout>
  );
};

export default Main;
