export enum TicketFormModelFieldType {
  SHORT_ANSWER = 'short-answer',
  LONG_ANSWER = 'long-answer',
  SINGLE_CHOICE = 'single-choice',
  MULTIPLE_CHOICE = 'multiple-choice',
  DROPDOWN = 'dropdown'
}

export interface ITicketFormModelField {
  fieldType: TicketFormModelFieldType;
  isRequired: boolean;
  isEmail?: boolean;
  maxLength?: number;
  name: string;
  options?: any;
  order: number;
}

export interface ITicketFormModelShortDTO {
  id: string;
  name: string;
  shortCode: string;
}

export interface ITicketFormModelDTO {
  id: string;
  name: string;
  shortCode: string;
  fields: ITicketFormModelField[];
  clientEmailField: number;
  allowForAttachmentsOnCreate: boolean;
  allowForAttachments: boolean;
}

export interface ITicketFormModelCreateDTO {
  submitted_fields: ITicketFormModelCreateSubmittedField[];
}

export interface ITicketFormModelCreateSubmittedField {
  order: number;
  answer?: TicketFormModelCreateSubmittedFieldAnswer;
  choice?: TicketFormModelCreateSubmittedFieldChoice;
}

export type TicketFormModelCreateSubmittedFieldAnswer = string;
export type TicketFormModelCreateSubmittedFieldChoice = number | number[] | null | undefined;

export type TicketFormModelCreateField = string | number | null | undefined;

export interface ITicketFormModelCreate {
  [key: string]: TicketFormModelCreateField;
}

export interface ITicketFormModel extends ITicketFormModelDTO {}

class TicketFormModel {
  constructor(
    public id: string,
    public name: string,
    public shortCode: string,
    public fields: ITicketFormModelField[],
    public clientEmailField: number,
    public allowForAttachmentsOnCreate: boolean,
    public allowForAttachments: boolean
  ) {}

  static build = (dto: ITicketFormModelDTO) =>
    new TicketFormModel(
      dto.id,
      dto.name,
      dto.shortCode,
      dto.fields.map((item, index) => {
        return {
          ...item,
          isEmail: index === dto.clientEmailField
        };
      }) || [],
      dto.clientEmailField,
      dto.allowForAttachmentsOnCreate,
      dto.allowForAttachments
    );

  static serialize(data: ITicketFormModelCreate, fields: ITicketFormModelField[] = []): ITicketFormModelCreateDTO {
    const submittedFields = fields
      .map((item: ITicketFormModelField) => {
        if (
          item.fieldType === TicketFormModelFieldType.SHORT_ANSWER ||
          item.fieldType === TicketFormModelFieldType.LONG_ANSWER
        ) {
          return {
            order: item.order,
            answer: data[`formField${item.order}`] as TicketFormModelCreateSubmittedFieldAnswer
          };
        } else {
          return {
            order: item.order,
            choice: data[`formField${item.order}`] as TicketFormModelCreateSubmittedFieldChoice
          };
        }
      })
      .filter(
        (item: ITicketFormModelCreateSubmittedField) =>
          item.answer || typeof item.choice === 'number' || Array.isArray(item.choice)
      );

    return { submitted_fields: submittedFields };
  }
}

export default TicketFormModel;
