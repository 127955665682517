import React from 'react';
import { Checkbox, FormItemProps, Radio, Select, Space } from 'antd';
import { ITicketFormModelField, TicketFormModelFieldType } from '@common/models/TicketFormModel';
import FormInput from '@common/components/FormInput';
import { Rule } from 'antd/es/form';
import FormTextArea from '@common/components/FormTextArea';
import FormCheckbox from '@common/components/FormCheckbox';
import FormRadioButton from '@common/components/FormRadioButton';
import { StyledTicketFormField } from './styled';

export interface IStyledTicketFormField extends FormItemProps {
  field: ITicketFormModelField;
}

interface ITicketFormField extends IStyledTicketFormField {
  getRules: (field: ITicketFormModelField) => Rule[];
}

const TicketFormField: React.FC<ITicketFormField> = ({ field, getRules, ...props }) => {
  const renderInput = (field: ITicketFormModelField) => {
    switch (field.fieldType) {
      case TicketFormModelFieldType.SHORT_ANSWER:
        return <FormInput max={field.maxLength} />;
      case TicketFormModelFieldType.LONG_ANSWER:
        return <FormTextArea max={field.maxLength} />;
      case TicketFormModelFieldType.SINGLE_CHOICE:
        return (
          <Radio.Group onChange={() => {}}>
            <Space direction="vertical">
              {field.options.map((value: string, index: number) => {
                return (
                  <FormRadioButton key={index} value={index}>
                    {value}
                  </FormRadioButton>
                );
              })}
            </Space>
          </Radio.Group>
        );
      case TicketFormModelFieldType.MULTIPLE_CHOICE:
        return (
          <Checkbox.Group onChange={() => {}}>
            <Space direction="vertical">
              {field.options.map((value: string, index: number) => {
                return (
                  <FormCheckbox key={index} value={index}>
                    {value}
                  </FormCheckbox>
                );
              })}
            </Space>
          </Checkbox.Group>
        );
      case TicketFormModelFieldType.DROPDOWN:
        return (
          <Select
            options={field.options?.map((option: string, index: number) => ({ value: index, label: option }))}
            onChange={() => {}}
          />
        );
    }
  };

  return (
    <StyledTicketFormField field={field} rules={getRules(field)} {...props}>
      {renderInput(field)}
    </StyledTicketFormField>
  );
};

export default TicketFormField;
