import { StyledEmpty } from './styled';

interface IEmptyProps {
  emptyMessage?: string;
}

const Empty: React.FC<IEmptyProps> = ({ emptyMessage, ...props }) => {
  return <StyledEmpty {...props} description={<span>{emptyMessage}</span>} />;
};

export default Empty;
