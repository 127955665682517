import React, { PropsWithChildren } from 'react';
import { FormProps } from 'antd';
import { ITicketFormModelField } from '@common/models/TicketFormModel';
import { StyledForm } from './styled';

interface ITicketForm extends FormProps {
  fields?: ITicketFormModelField[];
}
type ITicketFormComponent = ITicketForm & PropsWithChildren;

const Form: React.FC<ITicketFormComponent> = ({ children, fields, ...props }) => {
  return (
    <StyledForm fields={fields} {...props}>
      {children}
    </StyledForm>
  );
};

export default Form;
