import { RoutePaths } from '@common/constants/Paths';
import TicketCreate from './routes/TicketCreate';
import TicketList from './routes/TicketList';
import ListProvider from './routes/TicketList/context/List/ListProvider';
import TicketListAuth from './routes/TicketListAuth';
import TicketView from './routes/TicketView';
import { Route, Routes } from 'react-router-dom';

const Ticket: React.FC = () => {
  return (
    <Routes>
      <Route path={RoutePaths.TicketListAuth} element={<TicketListAuth />} />
      <Route
        path={RoutePaths.TicketList}
        element={
          <ListProvider>
            <TicketList />
          </ListProvider>
        }
      />
      <Route path={RoutePaths.TicketCreate} element={<TicketCreate />} />
      <Route path={RoutePaths.TicketView} element={<TicketView />} />
      <Route path={RoutePaths.TicketViewWithToken} element={<TicketView />} />
    </Routes>
  );
};

export default Ticket;
