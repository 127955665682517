import axios, { AxiosError } from 'axios';
import isPlainObject from 'lodash/isPlainObject';
import map from 'lodash/map';
import each from 'lodash/each';

export const mainApi = axios.create({
  headers: {
    Accept: 'application/json'
  },
  baseURL: process.env.REACT_APP_API_BASE_URL
});

export const isHttpError = (x: any): x is AxiosError => {
  return x.response !== undefined;
};

export const getDataFromAttributes = (item: any) => {
  const _item = { ...item };
  each(_item, (value: any, key) => {
    if (isPlainObject(value) && value.data) {
      _item[key] = getDataFromAttributes(value.data.attributes);
    } else if (Array.isArray(value)) {
      _item[key] = map(value, (i) => (i.data ? getDataFromAttributes(i.data.attributes) : i));
    }
  });

  return _item;
};
