import React, { PropsWithChildren, useState } from 'react';
import { IOrderBy, ListContext } from './ListContext';
import TicketModel, { ITicketListModelDTO } from '@common/models/TicketModel';
import { TicketListResource } from '@routes/Ticket/routes/TicketList/api/TicketListResource';
import { useParams } from 'react-router-dom';

const ListProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [tickets, setTickets] = useState<ITicketListModelDTO[]>([]);
  const [order, setOrder] = useState<IOrderBy>({ name: 'no', label: 'Data utworzenia', desc: false });
  const { instanceSlug } = useParams<{
    instanceSlug: string;
  }>();

  const fetchTickets = async (query: string) => {
    if (!instanceSlug) {
      return;
    }

    const authToken = localStorage.getItem('authToken') || '';
    const fetchParams: any = {};
    if (query) {
      fetchParams.search = query;
    }
    if (order) {
      fetchParams.ordering = order.desc ? `-${order.name}` : order.name;
    }
    const response = await TicketListResource.fetch(instanceSlug, authToken, fetchParams);
    setTickets(response.data.tickets.map((ticket) => TicketModel.build(ticket)));
  };

  return (
    <ListContext.Provider value={{ fetchTickets, tickets, setTickets, order, setOrder }}>
      {children}
    </ListContext.Provider>
  );
};

export default ListProvider;
