import { useIntl } from 'react-intl';
import Text from '@common/components/Text';
import { StyledSpin } from '../PageLoader/styled';
import FormPinInput from '../PinInput';
import { useCallback, useContext, useState } from 'react';
import CaptchaContext from '@common/contexts/Captcha';
import { TokenAuthResource } from '@common/api/TokenAuthResource';
import useAuthHelper from '@common/hooks/useAuthHelper';
import { notification } from 'antd';

export interface IAuthCode {
  email: string;
  onSuccess?: () => void;
  onError?: () => void;
}

const AuthCode: React.FC<IAuthCode> = ({ email, onError, onSuccess }) => {
  const intl = useIntl();
  const [isLoading, setLoading] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState<string>('');
  const { executeCaptcha } = useContext(CaptchaContext);
  const { saveAuthToLocalStorage } = useAuthHelper();

  const getAuthToken = useCallback(
    (confirmationCode: string) => {
      try {
        setLoading(true);
        executeCaptcha(async (token) => {
          if (!confirmationCode) {
            return;
          }
          const response = await TokenAuthResource.byCode({ code: confirmationCode, email }, token);
          if (response && response.status === 200) {
            saveAuthToLocalStorage(response.data.token, email);
            onSuccess && onSuccess();
          } else {
            notification.error({
              message: intl.formatMessage({ id: 'message.error.title' }),
              description: intl.formatMessage({ id: 'message.error.authCode' })
            });
            onError && onError();
          }
          setLoading(false);
        });
      } catch (err) {
        setLoading(false);
      }
    },
    [email]
  );

  return (
    <>
      <Text>
        {intl.formatMessage({ id: 'list.auth.enterCode' })} <strong>{email}</strong>
      </Text>
      <StyledSpin spinning={isLoading}>
        <FormPinInput
          value={confirmationCode}
          onChange={(value: string) => setConfirmationCode(value)}
          onComplete={() => getAuthToken(confirmationCode)}
          numInputs={4}
        />
      </StyledSpin>
    </>
  );
};

export default AuthCode;
