import styled from 'styled-components';
import { IStyledCharCounter } from './CharCounter';

export enum StatusType {
  DEFAULT = 'default',
  WARNING = 'warning',
  EXCEPTION = 'exception',
  SUCCESS = 'success'
}

export const StyledCharCounter = styled.div<IStyledCharCounter>`
  margin-top: -2px;
  &&& {
    .ant-progress {
      margin-left: 4px;
      .ant-progress-circle-path {
        stroke: ${({ $status, theme: { antd } }) =>
          ($status === StatusType.WARNING && antd.colorWarning) ||
          ($status === StatusType.EXCEPTION && antd.colorError) ||
          ($status === StatusType.SUCCESS && antd.colorSuccess)};
      }
    }
  }
`;
