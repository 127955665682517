import { Layout } from 'antd';
import styled from 'styled-components';
const { Content } = Layout;

export const StyledMain = styled(Content)`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background: ${({ theme }) => theme.antd.colorBgBase};
  justify-content: flex-start;
`;
