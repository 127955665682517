import { Checkbox } from 'antd';
import styled from 'styled-components';

export const StyledFormCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
  && {
    .ant-checkbox {
      top: 0;
    }
    .ant-checkbox-inner {
      transform: none;
      width: 22px;
      height: 22px;
      border-radius: 8px;
      border-width: 2px;
      transition: none;
    }
    .ant-checkbox-checked {
      &:after {
        border-radius: 8px;
        animation-name: none;
      }
      .ant-checkbox-inner {
        background-color: transparent;
        &::after {
          top: 50%;
          left: 50%;
          background-color: ${({ theme: { antd } }) => antd.colorPrimary};
          border: 0;
          border-radius: 8px;
          width: 8px;
          height: 8px;
          margin-top: -4px;
          margin-left: -4px;
          transform: none;
        }
      }
    }
  }
`;
