import React, { PropsWithChildren, useCallback, useState } from 'react';
import { UploadContext } from './UploadContext';
import { UploadFile } from 'antd';
import { mainApi } from '@common/services/ApiAgentService';
import axios from 'axios';

const UploadProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const updateFileList = useCallback((props: UploadFile[]) => {
    setFileList(props);
  }, []);

  const handleUpload = useCallback(
    (url: string) => {
      return axios.all(
        fileList.map((file: UploadFile) =>
          mainApi.post(
            url,
            {
              file
            },
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
        )
      );
    },
    [fileList]
  );

  return <UploadContext.Provider value={{ fileList, updateFileList, handleUpload }}>{children}</UploadContext.Provider>;
};

export default UploadProvider;
