import { StyledIcon } from '@common/components/Icon/styled';
import Logo from '@common/components/Logo';
import { StyledTicketColumnContent } from '@common/components/TicketLayout';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { StyledTicketView } from '../TicketView/styled';

export const StyledTicketList = styled(StyledTicketView)`
  ${StyledTicketColumnContent} {
    max-width: 60%;
    flex: 1;
    margin-top: 0;
  }
`;

export const StyledTicketListLogo = styled(Logo)`
  margin: 20px 0;
`;

export const StyledLink = styled(Link)`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: ${({ theme: { antd } }) => antd.colorText};
`;

export const StyledTicketAction = styled.div`
  display: flex;
  justify-content: flex-end;

  ${StyledIcon} {
    font-size: 20px;
    margin: 0;
  }

  ${StyledLink} {
    margin: 4px 10px;
  }
`;
