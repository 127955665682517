import { memo, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ActionButton from '@common/components/ActionButton';
import { NavigatePaths } from '@common/constants/Paths';
import { buildRouteUrl } from '@common/helpers/OtherHelper';
import Title from '@common/components/Title';
import Text from '@common/components/Text';
import { StyledActionButtonWrapper } from '@common/components/ActionButton/styled';
import { useIntl } from 'react-intl';
import { StyledCreateFormSuccess } from './styled';
import TicketModel from '@common/models/TicketModel';
import useAuthHelper from '@common/hooks/useAuthHelper';
import { IInstanceParam } from '@common/interfaces/UrlParams';
import AuthCode from '@common/components/AuthCode';
import { TokenAuthResource } from '@common/api/TokenAuthResource';
import CaptchaContext from '@common/contexts/Captcha';

export interface ICreateFormSuccess {
  ticket: TicketModel;
  reset: () => void;
}

const CreateFormSuccess: React.FC<ICreateFormSuccess> = ({ reset, ticket }) => {
  const intl = useIntl();
  const { getToken, authorize } = useAuthHelper();
  const { instanceSlug } = useParams<{ instanceSlug: string }>() as IInstanceParam;
  const { executeCaptcha } = useContext(CaptchaContext);
  const navigate = useNavigate();
  const [viewAuth, setViewAuth] = useState(false);

  const goToTicketView = () => {
    navigate(
      buildRouteUrl(NavigatePaths.TicketView, [
        {
          key: ':instanceSlug',
          value: instanceSlug
        },
        {
          key: ':shortCode',
          value: ticket.shortCode
        }
      ])
    );
  };

  const getAuthCode = async (email: string, rc_token: string) => {
    await TokenAuthResource.byCodeRequest({ instanceShortCode: instanceSlug, email: ticket.clientEmail }, rc_token);
  };

  const viewTicket = async () => {
    try {
      executeCaptcha(async (token) => {
        const authToken = getToken();
        if (authToken) {
          const response = await TokenAuthResource.validate(ticket.clientEmail, authToken);
          if (response.data.isValid) {
            goToTicketView();
          } else {
            getAuthCode(ticket.clientEmail, token);
            setViewAuth(true);
          }
        } else {
          getAuthCode(ticket.clientEmail, token);
          setViewAuth(true);
        }
      });
    } catch (error) {
      authorize(instanceSlug);
    }
  };

  if (viewAuth) {
    return <AuthCode email={ticket.clientEmail} onSuccess={goToTicketView} />;
  }

  return (
    <StyledCreateFormSuccess>
      <Title level={1} translateText="form.create.success" />
      <Text translateText="form.create.success.message" />
      <Text translateText="form.create.success.message.contact" />

      <StyledActionButtonWrapper>
        <ActionButton onClick={reset} gray text={intl.formatMessage({ id: 'goback' })} />
        <ActionButton onClick={viewTicket} text={intl.formatMessage({ id: 'form.create.view.ticket' })} />
      </StyledActionButtonWrapper>
    </StyledCreateFormSuccess>
  );
};

export default memo(CreateFormSuccess);
