import { devices, screenSize } from '@common/constants/LayoutParams';
import styled from 'styled-components';
import { StyledActionButton } from '../ActionButton/styled';
import { StyledText } from '../Text/styled';
import { StyledTitle } from '../Title/styled';
import { StyledPageVideo } from '../PageVideo/styled';
import plusOrange from '@assets/plus-orange.png';
import circleSolidWallOrange from '@assets/circle-solid-wall-orange.png';
import plusPrimary from '@assets/plus-primary.png';
import circleOrange from '@assets/circle-orange.png';

export const StyledTicketWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1;
`;

export const StyledTicketColumnLeft = styled.div`
  position: relative;
  flex-basis: 150px;
  height: 500px;

  @media (max-width: ${screenSize.M - 1}px) {
    display: none;
  }
`;

export const StyledTicketColumnContent = styled.div`
  flex-basis: 500px;

  @media ${devices.Medium} {
    ${StyledTitle} {
      font-size: 2.5em;
    }
    ${StyledText} {
      font-size: 1.2em;
    }
    ${StyledActionButton} {
      width: 100%;
      margin-right: 0;
    }
  }
  @media ${devices.Large} {
    ${StyledActionButton} {
      width: 100%;
      margin-right: 0;
    }
  }
`;

export const StyledTicketColumnRight = styled.div`
  position: relative;
  width: 800px;
  ${StyledPageVideo} {
    width: 100%;
    height: auto;
    outline: none;
    clip-path: inset(0 15px 0 66px);
    padding: 0 14px 30px 64px;
  }

  @media ${devices.Small} {
    display: none;
  }

  @media ${devices.Medium} {
    width: 720px;
  }

  @media ${devices.Large} {
    width: 760px;
  }

  @media ${devices.ExtraLarge} {
    width: 800px;
  }

  @media ${devices.ExtremeLarge} {
    width: 1000px;
  }
`;

export const StyledPlusOrange = styled.div`
  position: absolute;
  z-index: 1;
  top: 32%;
  right: 25%;
  width: 26px;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${plusOrange});
`;

export const StyledCircleSolidWallOrange = styled.div`
  position: absolute;
  top: 45%;
  left: 2%;
  width: 62px;
  height: 142px;
  background-repeat: no-repeat;
  background-image: url(${circleSolidWallOrange});
`;

export const StyledPlusPrimary = styled.div`
  position: absolute;
  z-index: 1;
  top: 2%;
  left: 6%;
  width: 22px;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${plusPrimary});
`;

export const StyledCircleOrange = styled.div`
  position: absolute;
  z-index: 1;
  top: 45%;
  left: 4%;
  width: 34px;
  height: 34px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${circleOrange});
`;
