import { AppConfig } from './AppConfig';
import UploadProvider from '@common/contexts/Upload/UploadProvider';
import Main from '../Main';

export const App: React.FC = () => {
  return (
    <AppConfig>
      <UploadProvider>
        <Main />
      </UploadProvider>
    </AppConfig>
  );
};
