import React from 'react';
import { ITicketFormModelShortDTO, TicketFormModelFieldType } from '@common/models/TicketFormModel';
import { ITicketModelSubmittedFormItem } from '@common/models/TicketModel';
import Text from '@common/components/Text';
import { useIntl } from 'react-intl';
import { StyledTicketFormInfo, StyledTicketFormInfoItem } from './styled';

interface ITicketFormInfo {
  form: ITicketFormModelShortDTO;
  fields: ITicketModelSubmittedFormItem[];
}

const TicketFormInfo: React.FC<ITicketFormInfo> = ({ form, fields, ...props }) => {
  const intl = useIntl();

  const renderFields = (field: ITicketModelSubmittedFormItem) => {
    switch (field.fieldType) {
      case TicketFormModelFieldType.SINGLE_CHOICE:
      case TicketFormModelFieldType.DROPDOWN:
        return typeof field.choice === 'number' ? (
          <>
            <label>{field.name}</label>: <span>{field.options[field.choice]}</span>
          </>
        ) : null;
      case TicketFormModelFieldType.MULTIPLE_CHOICE:
        return Array.isArray(field.choice) && field.choice.length > 0 ? (
          <>
            <label>{field.name}</label>: <span>{field.choice?.map((choice) => field.options[choice]).join(', ')}</span>
          </>
        ) : null;
      default:
        return (
          <>
            <label>{field.name}</label>: <span>{field.answer}</span>
          </>
        );
    }
  };

  return (
    <StyledTicketFormInfo>
      <Text>{intl.formatMessage({ id: 'form.info.modal.text' })}</Text>
      <StyledTicketFormInfoItem>
        <label>{intl.formatMessage({ id: 'form.info.modal.formName' })}</label>: <span>{form.name}</span>
      </StyledTicketFormInfoItem>
      {fields.map((field: ITicketModelSubmittedFormItem, index: number) => (
        <StyledTicketFormInfoItem key={index}>{renderFields(field)}</StyledTicketFormInfoItem>
      ))}
    </StyledTicketFormInfo>
  );
};

export default TicketFormInfo;
