import { ResultProps } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { StyledAccessDenied } from './styled';

const AccessDenied: React.FC<ResultProps> = ({ ...props }) => {
  const intl = useIntl();

  return (
    <StyledAccessDenied
      status="403"
      title="403"
      subTitle={intl.formatMessage({ id: 'page.accessDenied' })}
      {...props}
    />
  );
};

export default AccessDenied;
