import { UploadFile } from 'antd';
import { AxiosResponse } from 'axios';
import { createContext } from 'react';

interface IUploadContext {
  fileList: UploadFile[];
  updateFileList: (items: UploadFile[]) => void;
  handleUpload: (url: string) => Promise<AxiosResponse<any, any>[]>;
}

export const UploadContext = createContext<IUploadContext>({
  fileList: [],
  updateFileList: (items: UploadFile[]) => {},
  handleUpload: (url: string) => {
    return Promise.all([]);
  }
});
