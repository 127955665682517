import { NavigatePaths } from '@common/constants/Paths';
import { mainApi } from '@common/services/ApiAgentService';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthHelper from './useAuthHelper';

const useApiConfig = () => {
  const navigate = useNavigate();
  const { authorize } = useAuthHelper();
  const { instanceSlug } = useParams<{ instanceSlug: string }>();


  useEffect(() => {
    const interceptor = mainApi.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        switch (error.response?.status) {
          case 404:
            navigate(NavigatePaths.NotFound);
            break;
          case 401:
          case 403:
            instanceSlug ? authorize(instanceSlug) : navigate(NavigatePaths.AccessDenied);
            break;
        }
        return Promise.reject(error);
      }
    );
    return () => {
      mainApi.interceptors.response.eject(interceptor);
    }
  }, []);
};

export default useApiConfig;
