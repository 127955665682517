import ActionButton from '@common/components/ActionButton';
import { StyledActionButtonWrapper } from '@common/components/ActionButton/styled';
import Form from '@common/components/Form';
import FormInput from '@common/components/FormInput';
import Logo from '@common/components/Logo';
import ReCaptchaInfo from '@common/components/ReCaptchaInfo';
import WelcomeInfo from '@common/components/RoundedBox';
import Text from '@common/components/Text';
import {
  StyledCircleOrange,
  StyledCircleSolidWallOrange,
  StyledPlusOrange,
  StyledPlusPrimary,
  StyledTicketColumnContent,
  StyledTicketColumnLeft,
  StyledTicketColumnRight,
  StyledTicketWrapper
} from '@common/components/TicketLayout';
import Title from '@common/components/Title';
import { NavigatePaths } from '@common/constants/Paths';
import CaptchaContext from '@common/contexts/Captcha';
import { buildRouteUrl } from '@common/helpers/OtherHelper';
import { IInstanceParam } from '@common/interfaces/UrlParams';
import { StyledTicketView } from '@routes/Ticket/routes/TicketView/styled';
import { Form as AntdForm } from 'antd';
import { useCallback, useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { StyledFormWrapper } from './styled';
import AuthCode from '@common/components/AuthCode';
import { TokenAuthResource } from '@common/api/TokenAuthResource';

const TicketListAuth: React.FC = () => {
  const intl = useIntl();
  const { instanceSlug } = useParams<{ instanceSlug: string }>() as IInstanceParam;
  const [form] = AntdForm.useForm();
  const [isLoading, setLoading] = useState(false);
  const { renderInfo, isReady: isCaptchaReady, executeCaptcha } = useContext(CaptchaContext);
  const [isFirstStep, setIsFirstStep] = useState<boolean>(true);
  const navigate = useNavigate();
  const [authEmail, setAuthEmail] = useState<string>('');

  const renderReCaptcha = useMemo(
    () => <ReCaptchaInfo visible={isCaptchaReady}>{renderInfo()}</ReCaptchaInfo>,
    [isCaptchaReady, renderInfo]
  );

  const goToList = useCallback(() => {
    navigate(
      buildRouteUrl(NavigatePaths.TicketList, [
        {
          key: ':instanceSlug',
          value: instanceSlug
        }
      ])
    );
  }, []);

  const getCode = useCallback(async (values: any) => {
    try {
      setLoading(true);
      executeCaptcha(async (token) => {
        await TokenAuthResource.byCodeRequest({ instanceShortCode: instanceSlug, email: values.email }, token);
        setAuthEmail(values.email);
        setLoading(false);
        setIsFirstStep(false);
      });
    } catch (err) {
      setLoading(false);
    }
  }, []);

  const goBackToFirstStep = useCallback(() => {
    setIsFirstStep(true);
  }, []);

  return (
    <StyledTicketWrapper>
      <StyledTicketView>
        <StyledTicketColumnLeft>
          <StyledPlusOrange />
          <StyledCircleSolidWallOrange />
        </StyledTicketColumnLeft>
        <StyledTicketColumnContent>
          <StyledFormWrapper>
            {isFirstStep ? (
              <>
                <Form form={form} layout="vertical" name="ticketForm" size="large" onFinish={getCode}>
                  <AntdForm.Item
                    id="email"
                    name="email"
                    initialValue={authEmail}
                    required={true}
                    label={intl.formatMessage({ id: 'list.auth.email' })}
                  >
                    <FormInput name="email" max={200} />
                  </AntdForm.Item>
                  <StyledActionButtonWrapper>
                    <ActionButton type="primary" htmlType="submit" loading={isLoading}>
                      {intl.formatMessage({
                        id: 'list.auth.getCode'
                      })}
                    </ActionButton>
                  </StyledActionButtonWrapper>
                </Form>
              </>
            ) : (
              <AuthCode email={authEmail} onSuccess={goToList} onError={goBackToFirstStep} />
            )}
          </StyledFormWrapper>
        </StyledTicketColumnContent>

        <StyledTicketColumnRight>
          <StyledPlusPrimary />
          <StyledCircleOrange />

          <WelcomeInfo>{intl.formatMessage({ id: 'list.auth.welcome' })}</WelcomeInfo>
          <Title>{intl.formatMessage({ id: 'list.auth.welcome.title' })}</Title>
          <Text>{intl.formatMessage({ id: 'list.auth.description1' })}</Text>
          <Text>{intl.formatMessage({ id: 'list.auth.description2' })}</Text>
          <Text>{intl.formatMessage({ id: 'list.auth.description3' })}</Text>
          <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'list.auth.description4' }) }}></p>
        </StyledTicketColumnRight>
      </StyledTicketView>
      <Logo />
      {renderReCaptcha}
    </StyledTicketWrapper>
  );
};

export default TicketListAuth;
