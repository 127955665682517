import styled from 'styled-components';

export const StyledReCaptchaInfo = styled.div`
  color: ${({ theme: { antd } }) => antd.colorTextTertiary};
  font-size: 0.75rem;
  text-align: center;
  padding: 30px 40px 16px;

  a {
    color: rgb(0 0 0 / 75%);
    &:hover {
      color: ${({ theme: { antd } }) => antd.colorPrimary};
    }
  }
`;
