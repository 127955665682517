import { ParagraphProps } from 'antd/es/typography/Paragraph';
import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { StyledText } from './styled';

type TextPropsWithChildren = ParagraphProps & PropsWithChildren & { translateText?: string };

const Text: React.FC<TextPropsWithChildren> = ({ children, translateText, ...props }) => {
  const intl = useIntl();

  const renderText = () => {
    if (translateText) {
      return intl.formatMessage({
        id: translateText
      });
    } else {
      return <>{children}</>;
    }
  };

  return <StyledText {...props}>{renderText()}</StyledText>;
};

export default Text;
