import PageLoader from '@common/components/PageLoader/PageLoader';
import { NavigatePaths } from '@common/constants/Paths';
import { useEffectOnce } from '@common/helpers/ActionHelper';
import useAuthHelper from '@common/hooks/useAuthHelper';
import { IInstanceParam } from '@common/interfaces/UrlParams';
import { PropsWithChildren, useState } from 'react';
import { useMatch, useParams } from 'react-router-dom';

const TicketAuth: React.FC<PropsWithChildren> = ({ children }) => {
  const { instanceSlug } = useParams<{
    instanceSlug: string;
  }>() as IInstanceParam;
  const matchCreateTicket = useMatch(`${NavigatePaths.TicketCreate}`);
  const matchView = useMatch(`${NavigatePaths.TicketViewWithToken}`);
  const { authByExternalToken, getToken, authorize, clearAuthLocalStorage, isAuthenticated } = useAuthHelper();
  const [noAuthPath, setNoAuthPath] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState<boolean>(false);


  useEffectOnce(() => {
    const checkAuth = async () => {
      try {
        if (matchView && matchView.params.externalToken) {
          await authByExternalToken(instanceSlug, matchView.params.externalToken);
          setIsAuth(isAuthenticated());
        } else {
          if (matchCreateTicket) {
            setNoAuthPath(true);
          } else {
            const authToken = getToken();
            if (!authToken) {
              setNoAuthPath(true);
              authorize(instanceSlug);
            }
            setIsAuth(isAuthenticated());
          }
        }
      } catch (err) {
        clearAuthLocalStorage();
      }
    };
    checkAuth();
  });

  return isAuth || noAuthPath ? <>{children}</> : <PageLoader />;
};

export default TicketAuth;
