import Title from '@common/components/Title';
import Text from '@common/components/Text';
import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { StyledLogoutButton, StyledPageHeader } from './styled';
import { useEffectOnce } from '@common/helpers/ActionHelper';

const PageHeader: React.FC<PropsWithChildren> = ({ children }) => {
  const intl = useIntl();
  const [loggedEmail, setLoggedEmail] = React.useState<string>('');

  useEffectOnce(() => {
    const loggedEmail = localStorage.getItem('authEmail') || '';
    setLoggedEmail(loggedEmail);
  });

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('authEmail');
    window.location.reload();
  };

  return (
    <StyledPageHeader>
      <div>
        <Title>{intl.formatMessage({ id: 'welcome' })}</Title>
        <Text>
          {intl.formatMessage({ id: 'list.pageHeader.text' })} <a href={`mailto: ${loggedEmail}`}>{loggedEmail}</a>
        </Text>
      </div>

      <div>
        <StyledLogoutButton onClick={logout}>{intl.formatMessage({ id: 'logout' })}</StyledLogoutButton>
      </div>
    </StyledPageHeader>
  );
};

export default PageHeader;
