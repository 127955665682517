import { memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ActionButton from '@common/components/ActionButton';
import { NavigatePaths } from '@common/constants/Paths';
import { buildRouteUrl } from '@common/helpers/OtherHelper';
import Title from '@common/components/Title';
import Text from '@common/components/Text';
import { StyledActionButtonWrapper } from '@common/components/ActionButton/styled';
import { useIntl } from 'react-intl';
import { StyledTicketClose } from './styled';

export interface ICreateFormSuccess {
  shortCode: string;
}

const TicketClose: React.FC<ICreateFormSuccess> = ({ shortCode }) => {
  const intl = useIntl();
  const { instanceSlug } = useParams<{ instanceSlug: string }>();
  const navigate = useNavigate();

  const addNewTicket = () => {
    if (!instanceSlug) {
      return;
    }
    navigate(
      buildRouteUrl(NavigatePaths.TicketCreate, [
        {
          key: ':instanceSlug',
          value: instanceSlug
        },
        {
          key: ':shortCode',
          value: shortCode
        }
      ])
    );
  };

  return (
    <StyledTicketClose>
      <Title level={1} translateText="ticket.view.ticketClose.title" />
      <Text translateText="form.create.success.message" />
      <Text translateText="form.create.success.message.contact" />

      <StyledActionButtonWrapper>
        <ActionButton onClick={addNewTicket} text={intl.formatMessage({ id: 'ticket.addNew' })} />
      </StyledActionButtonWrapper>
    </StyledTicketClose>
  );
};

export default memo(TicketClose);
