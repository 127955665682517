import styled from 'styled-components';
import { StyledCharCounter } from '../CharCounter/styled';
import { Input } from 'antd';

export const StyledTextAreaWrapper = styled.div`
  position: relative;

  ${StyledCharCounter} {
    position: absolute;
    right: 11px;
    top: 10px;
  }
`;

export const StyledTextArea = styled(Input.TextArea)`
  padding-right: 35px;
  background-color: ${({ theme: { antd } }) => antd.colorBgBase};
`;
