import styled from 'styled-components';
import { StyledSearch } from './components/Search/styled';
import { devices } from '@common/constants/LayoutParams';

export const StyledListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;

  &&& {
    ${StyledSearch} {
      margin-top: 0;
      .ant-input-affix-wrapper {
        width: 40%;
      }

      @media ${devices.Medium} {
        .ant-input-affix-wrapper {
          width: 50%;
        }
      }

      @media ${devices.Small} {
        .ant-input-affix-wrapper {
          width: 60%;
        }
      }
    }
  }
`;
