import { Spin } from 'antd';
import styled from 'styled-components';

export const StyledSpin = styled(Spin)`
  position: fixed;
  top: calc(50% - 20px);
  right: calc(50% - 16px);
  z-index: 1000;
  .ant-spin-dot-item {
    background-color: ${({ theme: { antd } }) => antd.colorPrimary};
  }
`;
