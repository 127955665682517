import { TokenAuthResource } from '@common/api/TokenAuthResource';
import { NavigatePaths } from '@common/constants/Paths';
import { buildRouteUrl } from '@common/helpers/OtherHelper';
import { useNavigate } from 'react-router-dom';

interface IAuthLocalStorage {
  token: string | null;
  email: string | null;
}

const useAuthHelper = () => {
  const navigate = useNavigate();

  const saveAuthToLocalStorage = (token: string, email: string) => {
    localStorage.setItem('authToken', token);
    localStorage.setItem('authEmail', email);
  };

  const loadAuthLocalStorage = (): IAuthLocalStorage => {
    const token = localStorage.getItem('authToken') || null;
    const email = localStorage.getItem('authEmail') || null;
    return { token: token, email: email };
  };

  const clearAuthLocalStorage = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('authEmail');
  };

  const authByExternalToken = async (
    instanceSlug: string,
    externalToken: string,
    save: boolean = true
  ): Promise<string> => {
    try {
      const authRespone = await TokenAuthResource.auth({
        instanceShortCode: instanceSlug,
        ticketToken: externalToken
      });
      if (save) {
        saveAuthToLocalStorage(authRespone.data.token, authRespone.data.ticketClientEmail);
      }
      return authRespone.data.token;
    } catch (err) {
      clearAuthLocalStorage();
      return '';
    }
  };

  const getToken = (): string => loadAuthLocalStorage().token || '';
  const getEmail = (): string => loadAuthLocalStorage().email || '';

  const isAuthenticated = (): boolean => !!getToken();

  const authorize = (instanceSlug: string) => {
    navigate(
      buildRouteUrl(NavigatePaths.TicketListAuth, [
        {
          key: ':instanceSlug',
          value: instanceSlug
        }
      ])
    );
  };

  return {
    saveAuthToLocalStorage,
    loadAuthLocalStorage,
    clearAuthLocalStorage,
    authByExternalToken,
    getToken,
    getEmail,
    isAuthenticated,
    authorize
  };
};

export default useAuthHelper;
