import { ITicketListModelDTO } from '@common/models/TicketModel';
import { createContext } from 'react';

export interface IOrderBy {
  name: string;
  label: string;
  desc: boolean;
}

export interface IFilter {
  [key: string]: string;
}

export interface IListContext {
  fetchTickets: (query: string) => void;
  tickets: ITicketListModelDTO[];
  setTickets: (tickets: ITicketListModelDTO[]) => void;
  order: IOrderBy;
  setOrder: (order: any) => void;
}

export const ListContext = createContext<IListContext>({
  fetchTickets: (query: string) => {},
  tickets: [],
  setTickets: () => {},
  order: { name: '', label: '', desc: false },
  setOrder: () => {}
});
