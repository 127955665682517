import { StyledText } from '@common/components/Text/styled';
import styled from 'styled-components';

export const StyledTicketFormInfo = styled.div`
  ${StyledText} {
    font-size: 1.2em;
  }
`;

export const StyledTicketFormInfoItem = styled.div`
  padding: 10px 0;
  label {
    color: ${({ theme: { antd } }) => antd.colorTextSecondary};
  }
  span {
    font-weight: bold;
  }
`;
