import { Input } from 'antd';
import styled from 'styled-components';

export const StyledSearch = styled(Input.Search)`
  &&& {
    border-radius: 20px;
    input {
      background-color: ${({ theme: { colors } }) => colors.coolGray[50]};
    }
    .ant-input-affix-wrapper {
      border-radius: 20px;
      background-color: ${({ theme: { colors } }) => colors.coolGray[50]};
      border: none;
      height: 40px;
      box-shadow: none;
    }
    .ant-input-group-addon {
      display: none;
    }
  }
`;
