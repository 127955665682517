import AccessDenied from '@routes/AccessDenied';
import PageNotFound from '@routes/PageNotFound';
import { RoutePaths } from '@common/constants/Paths';
import Ticket from '@routes/Ticket';
import Home from '@routes/Home';
import { PropsWithChildren } from 'react';
import { Route, Routes } from 'react-router-dom';
import TicketAuth from '@routes/Ticket/components/TicketAuth/TicketAuth';

const MainRoutes: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Routes>
      <Route path={RoutePaths.Home} element={<Home />} />

      <Route
        path={RoutePaths.Ticket}
        element={
          <TicketAuth>
            <Ticket />
          </TicketAuth>
        }
      />

      <Route path={RoutePaths.NotFound} element={<PageNotFound />} />
      <Route path={RoutePaths.AccessDenied} element={<AccessDenied />} />
    </Routes>
  );
};

export default MainRoutes;
