import { StyledActionButton } from '@common/components/ActionButton/styled';
import styled from 'styled-components';

export const StyledTicketClose = styled.div`
  &&& {
    ${StyledActionButton} {
      font-size: 1.4em;
      height: 60px;
      font-weight: bold;
      min-width: 240px;
    }
  }
`;
