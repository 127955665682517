import { IHttpResponse } from '@common/interfaces/HttpInterface';
import { ITicketMessageCreateDTO, ITicketMessageModelDTO } from '@common/models/TicketMessage';
import { ITicketModelDTO } from '@common/models/TicketModel';
import { mainApi } from '@common/services/ApiAgentService';
import { AxiosRequestConfig } from 'axios';

export interface ITicketViewResponse {
  ticket: ITicketModelDTO;
  ticketMessages: ITicketMessageModelDTO[];
}

enum ITicketViewFileDownloadType {
  DIRECT = 'download-direct',
  NEW_TAB = 'download-new-tab'
}

export interface ITicketViewFileResponse {
  id: string;
  displayedName: string;
  extension?: string;
  downloadUrl: string;
  downloadType: ITicketViewFileDownloadType;
}

export const TicketViewResource = {
  get: (
    instanceSlug: string,
    shortCode: string,
    token: string,
    config: AxiosRequestConfig = {}
  ): Promise<IHttpResponse<ITicketViewResponse>> =>
    mainApi.get(`/ticket/${instanceSlug}/${shortCode}/`, {
      ...config,
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${token}`,
        ...config.headers
      }
    }),
  create: (
    instanceSlug: string,
    shortCode: string,
    data: ITicketMessageCreateDTO,
    token: string,
    rc_token: string,
    config: AxiosRequestConfig = {}
  ): Promise<IHttpResponse<ITicketMessageModelDTO>> => {
    return mainApi.post(
      `/ticket/${instanceSlug}/${shortCode}/ticket-message/`,
      { ...data, rc_token },
      {
        ...config,
        headers: {
          Accept: 'application/json',
          Authorization: `Token ${token}`,
          ...config.headers
        }
      }
    );
  },
  getFile: (
    instanceSlug: string,
    shortCode: string,
    messageId: string,
    fileId: string,
    token: string,
    config: AxiosRequestConfig = {}
  ): Promise<IHttpResponse<ITicketViewFileResponse>> =>
    mainApi.get(`/ticket/${instanceSlug}/${shortCode}/ticket-message/${messageId}/ticket-file/${fileId}/`, {
      ...config,
      headers: {
        Accept: 'application/json',
        Authorization: `Token ${token}`,
        ...config.headers
      }
    })
};
