import { Col, Row } from 'antd';
import React from 'react';
import { StyledLogo, StyledLogoWrapper } from './styled';
const logo = require('@assets/logo.png');

const Logo: React.FC = ({ ...props }) => {
  return (
    <StyledLogoWrapper>
      <Row align="middle" justify="center" gutter={8}>
        <Col>Powered by</Col>
        <Col>
          <StyledLogo src={logo} {...props}></StyledLogo>
        </Col>
      </Row>
    </StyledLogoWrapper>
  );
};

export default Logo;
