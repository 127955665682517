import { InputProps } from 'antd';
import React, { useMemo } from 'react';
import CharCounter from '../CharCounter/CharCounter';
import { Rule } from 'antd/es/form';
import { StyledFormInput } from './styled';

export interface IFormInputProps extends InputProps {
  $showCharCounter?: boolean;
  rules?: Rule[];
  max?: number;
}

const FormInput: React.FC<IFormInputProps> = ({ max, suffix, rules, ...props }) => {
  const renderCounter = useMemo(() => {
    if (!max) {
      return null;
    }
    return <CharCounter value={props.value as string} max={max} />;
  }, [props.value, rules]);

  return <StyledFormInput {...props} suffix={suffix || renderCounter} />;
};

export default FormInput;
