import React from 'react';
import { StyledPageVideo } from './styled';

interface IPageVideo {
  video: string;
}

const PageVideo: React.FC<IPageVideo> = ({ video, ...props }) => {
  return <StyledPageVideo loop autoPlay muted src={video} {...props} />;
};

export default PageVideo;
