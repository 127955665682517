import other from '@common/lang/locales/de.json';
import { ITranslationConfig } from '@common/lang/config/types';
import { InstanceLanguage } from '@ngroup-pl/icp-typescript-utility-types/lib/api/InstanceLanguage';

const lang: ITranslationConfig = {
  messages: other,
  locale: InstanceLanguage.de
};

export default lang;
