import { SearchProps } from 'antd/lib/input';
import Icon from '@common/components/Icon';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { useContext } from 'react';
import { ListContext } from '@routes/Ticket/routes/TicketList/context/List/ListContext';
import { StyledSearch } from './styled';
import { useIntl } from 'react-intl';

const Search: React.FC<SearchProps> = ({ ...props }) => {
  const intl = useIntl();
  const { fetchTickets } = useContext(ListContext);

  const onSearch = (value: string) => {
    fetchTickets(value);
  };

  return (
    <StyledSearch
      allowClear
      placeholder={intl.formatMessage({ id: 'list.search.placeholder' })}
      prefix={<Icon icon={faSearch} />}
      enterButton={null}
      onSearch={onSearch}
      {...props}
    />
  );
};

export default Search;
