import { ResultProps } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { StyledPageNotFound } from './styled';

const PageNotFound: React.FC<ResultProps> = ({ ...props }) => {
  const intl = useIntl();

  return (
    <StyledPageNotFound status="404" title="404" subTitle={intl.formatMessage({ id: 'page.notFound' })} {...props} />
  );
};

export default PageNotFound;
