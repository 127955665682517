export const LayoutParams = {
  systemName: 'IC Project'
};

export const screenSize = {
  XXS: 600,
  XS: 800,
  S: 1200,
  M: 1400,
  L: 1600,
  XL: 1750
};

export const devices = {
  ExtremeSmall: `(max-width: ${screenSize.XXS - 1}px)`,
  Small: `(max-width: ${screenSize.S - 1}px)`,
  Medium: `(min-width: ${screenSize.S}px) and (max-width: ${screenSize.M - 1}px)`,
  Large: `(min-width: ${screenSize.M}px) and (max-width: ${screenSize.L - 1}px)`,
  ExtraLarge: `(min-width: ${screenSize.L}px) and (max-width: ${screenSize.XL - 1}px)`,
  ExtremeLarge: `(min-width: ${screenSize.XL}px)`
};
