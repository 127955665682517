import React from 'react';
import { useIntl } from 'react-intl';
import { StyledTicketStatus } from './styled';

export interface IProps {
  $isOpen: boolean;
}

const TicketStatus: React.FC<IProps> = ({ $isOpen }) => {
  const intl = useIntl();

  return (
    <StyledTicketStatus $isOpen={$isOpen}>
      <span>
        {intl.formatMessage({
          id: $isOpen ? 'list.status.open' : 'list.status.closed'
        })}
      </span>
    </StyledTicketStatus>
  );
};

export default TicketStatus;
