import React from 'react';
import { ITicketModelDTO } from '@common/models/TicketModel';
import TicketMessageModel, { ITicketMessageFile } from '@common/models/TicketMessage';
import { UserOutlined } from '@ant-design/icons';
import { downloadFileFromUrl, formatFileSize, getFileIconByExtension } from '@common/helpers/FileHelper';
import Icon from '@common/components/Icon';
import { TicketViewResource } from '@routes/Ticket/routes/TicketView/api/TicketViewResource';
import { useParams } from 'react-router-dom';
import { FormattedDate, useIntl } from 'react-intl';
import {
  StyledAvatar,
  StyledFileInfo,
  StyledFileInfoFileName,
  StyledFileInfoSize,
  StyledFormattedDate,
  StyledMessageContent,
  StyledMessageFileItem,
  StyledMessageFiles,
  StyledTicketMessage
} from './styled';
import useAuthHelper from '@common/hooks/useAuthHelper';

interface ITicketMessage {
  message: TicketMessageModel;
  ticket: ITicketModelDTO;
}

const TicketMessage: React.FC<ITicketMessage> = ({ message, ticket, ...props }) => {
  const intl = useIntl();
  const { instanceSlug } = useParams<{
    instanceSlug: string;
  }>();
  const { getToken } = useAuthHelper();

  const downloadFile = async (file: ITicketMessageFile) => {
    if (!instanceSlug) {
      return;
    }
    const response = await TicketViewResource.getFile(instanceSlug, ticket.shortCode, message.id, file.id, getToken());
    downloadFileFromUrl(response.data.downloadUrl, `${file.displayedName}.${file.extension}`);
  };

  return (
    <StyledTicketMessage $external={!message.createdBy}>
      <StyledAvatar
        size={48}
        icon={message.creatorInitials ? <span>{message.creatorInitials}</span> : <UserOutlined />}
        src={message.createdBy?.pictureSm}
      />
      <StyledMessageContent>
        <strong>{message.creatorName || ticket.clientName}</strong>{' '}
        <small>
          <span>{intl.formatMessage({ id: 'formatDate.inDay' })}</span>
          <StyledFormattedDate>
            <FormattedDate
              value={message.createdAt}
              year="numeric"
              month="long"
              day="2-digit"
              hour="numeric"
              minute="numeric"
            />
          </StyledFormattedDate>
        </small>
        <p dangerouslySetInnerHTML={{ __html: message.content }}></p>
        {message.ticketFiles.length > 0 ? (
          <StyledMessageFiles>
            {message.ticketFiles.map((file, index) => (
              <StyledMessageFileItem key={index} onClick={() => downloadFile(file)}>
                <Icon icon={getFileIconByExtension(file.extension)} />
                <StyledFileInfo>
                  <StyledFileInfoFileName>{file.displayedName}</StyledFileInfoFileName>
                  <StyledFileInfoSize>{formatFileSize(file.size)}</StyledFileInfoSize>
                </StyledFileInfo>
              </StyledMessageFileItem>
            ))}
          </StyledMessageFiles>
        ) : null}
      </StyledMessageContent>
    </StyledTicketMessage>
  );
};

export default TicketMessage;
