import {
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord
} from '@fortawesome/pro-regular-svg-icons';

export enum FileExtensionType {
  pdfFile = 'pdfFile',
  wordFile = 'wordFile',
  codeFile = 'codeFile',
  excelFile = 'excelFile',
  powerPointFile = 'powerPointFile',
  textFile = 'textFile',
  archiveFile = 'archiveFile',
  imageFile = 'imageFile',
  audioFile = 'audioFile',
  videoFile = 'videoFile'
}

export const FileExtensionTypeIcon = {
  pdfFile: faFilePdf,
  wordFile: faFileWord,
  codeFile: faFileCode,
  excelFile: faFileExcel,
  powerPointFile: faFilePowerpoint,
  textFile: faFileAlt,
  archiveFile: faFileArchive,
  imageFile: faFileImage,
  audioFile: faFileAudio,
  videoFile: faFileVideo,
  otherFile: faFile
};

export const FileExtension = {
  [FileExtensionType.pdfFile]: ['pdf'],
  [FileExtensionType.wordFile]: ['doc', 'dot', 'wbk', 'docx', 'docm', 'dotx', 'dotm', 'docb'],
  [FileExtensionType.codeFile]: ['xml'],
  [FileExtensionType.excelFile]: [
    'xls',
    'xlt',
    'xlm',
    'xlsx',
    'xlsm',
    'xltx',
    'xltm',
    'xlsb',
    'xla',
    'xlam',
    'xll',
    'xlw'
  ],
  [FileExtensionType.powerPointFile]: [
    'ppt',
    'pot',
    'pps',
    'pptx',
    'pptm',
    'potx',
    'potm',
    'ppam',
    'ppsx',
    'ppsm',
    'sldx',
    'sldm'
  ],
  [FileExtensionType.textFile]: ['rtf', 'txt', 'odt'],
  [FileExtensionType.archiveFile]: ['7z', 'arj', 'deb', 'pkg', 'rar', 'rpm', 'tar.gz', 'z', 'zip'],
  [FileExtensionType.imageFile]: ['ai', 'bmp', 'gif', 'ico', 'jpeg', 'jpg', 'png', 'ps', 'psd', 'svg', 'tif', 'tiff'],
  [FileExtensionType.audioFile]: ['aif', 'cda', 'mid', 'midi', 'mp3', 'mpa', 'ogg', 'wav', 'wma', 'wpl']
};
