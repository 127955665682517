import { InstanceLanguage } from '@ngroup-pl/icp-typescript-utility-types/lib/api/InstanceLanguage';
import { ITranslationConfig } from '@common/lang/config/types';
import other from '@common/lang/locales/en.json';

const lang: ITranslationConfig = {
  messages: other,
  locale: InstanceLanguage.en
};

export default lang;
