import { TitleProps } from 'antd/es/typography/Title';
import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { StyledTitle } from './styled';

export type TitlePropsWithChildren = TitleProps & PropsWithChildren & { translateText?: string; $center?: boolean };

const Title: React.FC<TitlePropsWithChildren> = ({ children, translateText, ...props }) => {
  const intl = useIntl();

  const renderText = () => {
    if (translateText) {
      return intl.formatMessage({
        id: translateText
      });
    } else {
      return children;
    }
  };

  return <StyledTitle {...props}>{renderText()}</StyledTitle>;
};

export default Title;
