export const RoutePaths = {
  Home: '/',
  Ticket: ':instanceSlug/*',
  TicketListAuth: 'auth',
  TicketList: 'list',
  TicketCreate: 'form/:shortCode',
  TicketView: 'view/:shortCode',
  TicketViewWithToken: 'view/:shortCode/:externalToken',
  NotFound: 'not-found',
  AccessDenied: 'access-denied'
};

export const NavigatePaths = {
  Home: '/',
  TicketListAuth: '/:instanceSlug/auth',
  TicketList: '/:instanceSlug/list',
  TicketCreate: '/:instanceSlug/form/:shortCode',
  TicketView: '/:instanceSlug/view/:shortCode/',
  TicketViewWithToken: '/:instanceSlug/view/:shortCode/:externalToken',
  NotFound: '/not-found',
  AccessDenied: '/access-denied'
};
