import React, { useMemo } from 'react';
import CharCounter from '../CharCounter';
import { Rule } from 'antd/es/form';
import { TextAreaProps } from 'antd/es/input';
import { StyledTextArea, StyledTextAreaWrapper } from './styled';

export interface IFormInputProps extends TextAreaProps {
  rules?: Rule[];
  max?: number;
}

const FormTextArea: React.FC<IFormInputProps> = ({ max, rules, ...props }) => {
  const renderCounter = useMemo(() => {
    if (!max) {
      return null;
    }
    return <CharCounter value={props.value as string} max={max} />;
  }, [props.value, rules]);

  return (
    <StyledTextAreaWrapper>
      <StyledTextArea {...props} />
      {renderCounter}
    </StyledTextAreaWrapper>
  );
};

export default FormTextArea;
