import { Button } from 'antd';
import styled from 'styled-components';
import { StyledActionButtonProps } from './ActionButton';
import { devices } from '@common/constants/LayoutParams';
import { lighten } from 'polished';

export const StyledActionButton = styled(Button)<StyledActionButtonProps>`
  && {
    font-size: 1.4em;
    height: 60px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 10px;
    box-shadow: none;
    text-shadow: none;
    background-color: ${({ theme, $gray }) => ($gray ? theme.antd.colorFillQuaternary : theme.antd.colorPrimary)};
    border-color: ${({ theme, $gray }) => ($gray ? theme.antd.colorFillQuaternary : theme.antd.colorPrimary)};
    color: ${({ theme, $gray }) => ($gray ? 'rgba(0, 0, 0, 0.9)' : '#fff')};
  }
  &&:hover,
  &&:focus,
  &&:active {
    background-color: ${({ theme, $gray }) =>
      $gray ? lighten(0.04, theme.antd.colorFillQuaternary) : theme.antd.colorPrimary};
    border-color: ${({ theme, $gray }) =>
      $gray ? lighten(0.04, theme.antd.colorFillQuaternary) : theme.antd.colorPrimary};
    color: ${({ theme, $gray }) => ($gray ? 'rgba(0, 0, 0, 0.9)' : '#fff')};
  }
  &&:disabled {
    opacity: 0.5;
  }
`;

export const StyledActionButtonWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  ${StyledActionButton} {
    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @media ${devices.ExtremeSmall} {
    ${StyledActionButton} {
      margin-bottom: 10px;
      &:not(:last-child) {
        margin-right: 0;
      }
    }
    justify-content: stretch;
    flex-direction: column;
  }
`;
