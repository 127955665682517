export enum TicketModelSourceType {
  FORM = 'form',
  MANUAL = 'manual',
  EMAIL = 'email'
}

export enum ITicketMessageFileSource {
  INTERNAL = 'internal',
  LINK = 'link'
}

export interface ITicketMessageFile {
  id: string;
  displayedName: string;
  extension?: string;
  size?: number;
  source: ITicketMessageFileSource;
  createdAt: string;
  createdBy?: ITicketCreatedBy;
  path: string;
}

export interface ITicketMessageCreateDTO {
  messageContent: string;
}

export interface ITicketMessageModelDTO {
  id: string;
  content: string;
  isIncoming: boolean;
  createdBy?: ITicketCreatedBy;
  createdAt: string;
  ticketFiles: ITicketMessageFile[];
  isAttachmentSizeExceeded: boolean;
}

export interface ITicketCreatedBy {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  pictureSm?: string;
  contractorName?: string;
  jobPositionName?: string;
  departmentName?: string;
}

class TicketMessageModel {
  constructor(
    public id: string,
    public content: string,
    public isIncoming: boolean,
    public createdAt: string,
    public ticketFiles: ITicketMessageFile[],
    public isAttachmentSizeExceeded: boolean,
    public createdBy?: ITicketCreatedBy
  ) {}

  static build = (dto: ITicketMessageModelDTO) =>
    new TicketMessageModel(
      dto.id,
      dto.content,
      dto.isIncoming,
      dto.createdAt,
      dto.ticketFiles,
      dto.isAttachmentSizeExceeded,
      dto.createdBy
    );

  static load = (dto: ITicketMessageModelDTO[]) => {
    return dto.map((item) => TicketMessageModel.build(item));
  };

  get creatorInitials(): string {
    if (this.createdBy) {
      const { firstName, lastName } = this.createdBy;
      return `${firstName ? firstName.charAt(0) : ''}${lastName ? lastName.charAt(0) : ''}`.toUpperCase();
    } else {
      return '';
    }
  }

  get creatorName(): string | null {
    if (this.createdBy) {
      const { firstName, lastName } = this.createdBy;
      return `${firstName} ${lastName}`;
    } else {
      return null;
    }
  }

  static serialize() {}
}

export default TicketMessageModel;
